function getCookie(name: string) {
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row?.toLowerCase().startsWith(name?.toLowerCase()))
    ?.split('=')[1];
  return cookieValue;
}

//This is version picker script referenced by shell consumers on their pages
//It is responsible for dynamically loading he-shell component of given version and locale
//Version is added during build time while locale is identified during runtime with the help of a cookie which FD passes
//The he-shell component path (https://<<CDNEndpoint>>/{version}/{locale}/he-shell-component.js) is identified and added during build time
//based on env specific build command i.e. build-dev, build-test or build (for prod)
async function loadShell() {
  let shellPath = 'https://harmony-portalplatform-test.azureedge.net/he-shell/{version}/{locale}/he-shell-component.js';
  const locale = getCookie('MarketplaceSelectedLocale') ?? 'en-us';
  const version = '5.9.0';
  shellPath = shellPath.replace('{locale}', locale);
  shellPath = shellPath.replace('{version}', version);
  //Dynamically load shell
  await import(shellPath);
  const buildNumber: string = '2024.11.8.1';
  console.log(`Shell (version ${version})(Build ${buildNumber}) loaded.`);
}

loadShell();
